import {
  PageSolution,
  Circles,
  CircleCenter,
  Description,
  CardInfo,
  CircleIcon,
  Line,
  BigCircle,
  UserLine,
  ImageResponsive,
} from './styles'

import Responsive from '../../assets/images/Group 69.png'
import Bitcoin from '../../assets/images/bitcoin.png'
import Tron from '../../assets/images/tron.png'
import Ripple from '../../assets/images/ripple.png'
import Bnb from '../../assets/images/bnb.png'
import Cardano from '../../assets/images/cardano.png'
import Ethereum from '../../assets/images/ethereum.png'
import Coin from '../../assets/images/hashlar_moeda.png'
import Client from '../../assets/images/cliente.png'

export const Solution = () => {
  return (
    <PageSolution id="solution">
      <CardInfo>
        <h1>Nossa solução</h1>

        <Description>
          A nossa solução, opera em forma de HUB, integrando diversas
          criptomoedas ao sistema de pagamentos e gestão de vendas. Com uma
          única integração você pode comercializar seus imóveis ou receber
          parcelas de pagamentos em diversas criptomoedas.
        </Description>
      </CardInfo>

      <Circles>
        <Line direction="0" />
        <BigCircle circle="1">
          <CircleIcon>
            <img src={Bitcoin} alt="Bitcoin" />
          </CircleIcon>
        </BigCircle>

        <Line direction="60" />
        <BigCircle circle="2">
          <CircleIcon>
            <img src={Tron} alt="Tron" />
          </CircleIcon>
        </BigCircle>

        <Line direction="120" />
        <BigCircle circle="3">
          <CircleIcon>
            <img src={Ripple} alt="Ripple" />
          </CircleIcon>
        </BigCircle>

        <CircleCenter>
          <img src={Coin} alt="Coin" />
        </CircleCenter>

        <Line direction="180" />
        <BigCircle circle="4">
          <CircleIcon>
            <img src={Bnb} alt="Bnb" />
          </CircleIcon>
        </BigCircle>

        <Line direction="240" />
        <BigCircle circle="5">
          <CircleIcon>
            <img src={Cardano} alt="Cardano" />
          </CircleIcon>
        </BigCircle>

        <UserLine direction="270" />
        <BigCircle client circle="7">
          <CircleIcon client>
            <img src={Client} alt="Client" />
          </CircleIcon>
        </BigCircle>

        <Line direction="300" />
        <BigCircle circle="6">
          <CircleIcon>
            <img src={Ethereum} alt="Ethereum" />
          </CircleIcon>
        </BigCircle>
      </Circles>

      <ImageResponsive>
        <img src={Responsive} alt="" />
      </ImageResponsive>
    </PageSolution>
  )
}
