import logo from '../../assets/images/logo2.png'
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarLink,
  MDBCollapse,
  MDBNavbarBrand
} from 'mdb-react-ui-kit'
import { NavContainer } from './style';

export const Navbar = () => {
  const [showNavSecond, setShowNavSecond] = useState(false)

  const [offset, setOffset] = useState(0)

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset)
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
  }, [])

  if (document.getElementById('Navbar') != null && window.screen.width >= 990) {
    if (offset > 300) {
      document.getElementById('Navbar').classList.add('bg-color')
    } else {
      document.getElementById('Navbar').classList.remove('bg-color')
    }
  }

  useEffect(() => {
    if (window.screen.width <= 990) {
      let nav = document.getElementById('Navbar')
        nav.classList.add('bg-color')
      }
  }, [])

  return (
    <NavContainer>
      <MDBNavbar expand="lg" light id="Navbar">
          <MDBContainer fluid>
            <MDBNavbarBrand href="#">
              <img src={logo} alt="Logo" />
            </MDBNavbarBrand>
            <MDBNavbarToggler
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setShowNavSecond(!showNavSecond)}
            >
              <svg height="48" width="48" fill="#fff">
                <path d="M5.15 37.4V32.7H42.85V37.4ZM5.15 26.35V21.65H42.85V26.35ZM5.15 15.3V10.55H42.85V15.3Z" />
              </svg>
            </MDBNavbarToggler>
            <MDBCollapse navbar show={showNavSecond}>
              <MDBNavbarNav>
                <MDBNavbarLink href="#what-is" onClick={() => setShowNavSecond(!showNavSecond)}>Sobre</MDBNavbarLink>
                <MDBNavbarLink href="#how-works" onClick={() => setShowNavSecond(!showNavSecond)}>Como funciona</MDBNavbarLink>
                <MDBNavbarLink href="#benefits" onClick={() => setShowNavSecond(!showNavSecond)}>Vantagens </MDBNavbarLink>
                <MDBNavbarLink href="#solution" onClick={() => setShowNavSecond(!showNavSecond)}>Nossa Solução</MDBNavbarLink>
                <MDBNavbarLink href="#contact" onClick={() => setShowNavSecond(!showNavSecond)}>Contato</MDBNavbarLink>
                <MDBNavbarLink><Link className='link_text' to="follow-your-order">Acompanhe seu pedido</Link></MDBNavbarLink>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
        {
          showNavSecond &&

          <div className='space_back' onClick={() => setShowNavSecond(!showNavSecond)}></div>
        }
    </NavContainer>
  )
}
