import {
  ArrowLeft,
  ArrowRight,
  CardBoard,
  Number,
  Rectangle,
  Text
} from './styles'

export const Board = ({ orderNumber, label, left, right }) => {
  return (
    <CardBoard>
      {left ? <ArrowRight color="white" /> : <ArrowRight color="transparent" />}
      <Rectangle>
        <Number> {orderNumber} </Number>
        <Text> {label} </Text>
      </Rectangle>

      {right ? <ArrowLeft color="white" /> : <ArrowLeft color="transparent" />}
    </CardBoard>
  )
}
