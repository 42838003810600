import React from 'react'
import './global.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import SnackbarProvider from 'react-simple-snackbar'
import { Home } from './Pages/home/Home';
import { FollowYourOrder } from './Pages/follow-your-order';
import { DetailsOrder } from './Pages/details-order';

function App() {

  return (
    <SnackbarProvider id="app">
    <div className='screen'>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={ <Home /> } />
          <Route path='/follow-your-order/' element={ <FollowYourOrder /> } />
          <Route path='/follow-your-order/:phase' element={ <FollowYourOrder /> } />
          <Route path='/details-order/:id' element={<DetailsOrder />}/>
          <Route></Route>
        </Routes>
      </ BrowserRouter>
    </div>
    </SnackbarProvider>
  );
}

export default App;
