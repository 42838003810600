import { useState } from 'react'
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai'

import {
  PageBenefits,
  Title,
  Polygon,
  Triangle,
  TrapezeRight,
  TrapezeLeft,
  PentagonRight,
  PentagonLeft,
  Pentagons,
  Trapezes,
  Line,
  Desc,
  CardInfo,
  CardMobile,
  DescMobile,
  ResponsiveDesc,
  Card,
  Arrows
} from './styles.js'

import {
  CoinIcon,
  GraficIcon,
  HandsIcon,
  PcIcon,
  TimerIcon
} from '../Icons.jsx'

export const Benefits = () => {
  const [selectPoligon, setSelectPoligon] = useState('pentagonRight')
  const [polygonMobile, setPolygonMobile] = useState(1)

  const check = () => {
    if (polygonMobile === 1) setSelectPoligon('pentagonLeft')
    else if (polygonMobile === 2) setSelectPoligon('pentagonRight')
    else if (polygonMobile === 3) setSelectPoligon('trapezeLeft')
    else if (polygonMobile === 4) setSelectPoligon('trapezeRight')
    else if (polygonMobile === 5) setSelectPoligon('triangle')
  }

  const next = () => {
    check()
    setPolygonMobile(polygonMobile + 1)

    if (polygonMobile === 5) {
      setPolygonMobile(1)
    }
  }

  const back = () => {
    check()
    setPolygonMobile(polygonMobile - 1)

    if (polygonMobile === 1) {
      setPolygonMobile(5)
    }
  }

  return (
    <PageBenefits id="benefits">
      <Title>Vantagens</Title>

      <Card>
        <Polygon>
          <Pentagons>
            <PentagonLeft
              color={selectPoligon === 'pentagonLeft' ? '#66d81d' : '#777777'}
              onClick={() => setSelectPoligon('pentagonLeft')}
            >
              <GraficIcon />
            </PentagonLeft>
            {selectPoligon === 'pentagonLeft' && (
              <CardInfo id="info_pentagonLeft">
                <Line direction="pentagonLeft"></Line>
                <Desc polygon="pentagonLeft">
                  Um melhor controle de gestão financeira, para cada etapa do
                  processo.
                </Desc>
              </CardInfo>
            )}

            <PentagonRight
              color={selectPoligon === 'pentagonRight' ? '#66d81d' : '#D1D1D1'}
              onClick={() => setSelectPoligon('pentagonRight')}
            >
              <HandsIcon />
            </PentagonRight>
            {selectPoligon === 'pentagonRight' && (
              <CardInfo>
                <Line></Line>
                <Desc polygon="pentagonRight">
                  Permite transações financeiras com diversas criptomoedas,
                  aumentando a capilaridade e alcance de vendas.
                </Desc>
              </CardInfo>
            )}
          </Pentagons>

          <Trapezes>
            <TrapezeLeft
              color={selectPoligon === 'trapezeLeft' ? '#66d81d' : '#ACACAC'}
              onClick={() => setSelectPoligon('trapezeLeft')}
            >
              <TimerIcon />
            </TrapezeLeft>
            {selectPoligon === 'trapezeLeft' && (
              <CardInfo>
                <Line direction="trapezeLeft"></Line>
                <Desc polygon="trapezeLeft">
                  Redução de intermediários no processo de compra e venda, dando
                  a cada negócio mais poder e autonomia.
                </Desc>
              </CardInfo>
            )}

            <TrapezeRight
              color={selectPoligon === 'trapezeRight' ? '#66d81d' : '#8E8E8E'}
              onClick={() => setSelectPoligon('trapezeRight')}
            >
              <PcIcon />
            </TrapezeRight>
            {selectPoligon === 'trapezeRight' && (
              <CardInfo>
                <Line></Line>
                <Desc polygon="trapezeRight">
                  Um melhor controle de gestão financeira, para cada etapa do
                  processo.
                </Desc>
              </CardInfo>
            )}
          </Trapezes>

          <Triangle
            color={
              selectPoligon === 'triangle' &&
              selectPoligon !== 'pentagonLeft' &&
              selectPoligon !== 'pentagonRight'
                ? '#66d81d'
                : '#777777'
            }
            onClick={() => setSelectPoligon('triangle')}
          >
            <CoinIcon />
          </Triangle>
          {selectPoligon === 'triangle' && (
            <CardInfo>
              <Line direction="triangle"></Line>
              <Desc polygon="triangle">
                Confirmação de recebimento na tela do sistema, agilizando o
                processo e ajudando a mitigar fraudes.
              </Desc>
            </CardInfo>
          )}
        </Polygon>
      </Card>

      <ResponsiveDesc>
        <CardMobile>
          {selectPoligon === 'pentagonLeft' && (
            <DescMobile>
              Um melhor controle de gestão financeira, para cada etapa do
              processo.
            </DescMobile>
          )}
          {selectPoligon === 'pentagonRight' && (
            <DescMobile>
              Permite transações financeiras com diversas criptomoedas,
              aumentando a capilaridade e alcance de vendas.
            </DescMobile>
          )}
          {selectPoligon === 'trapezeLeft' && (
            <DescMobile>
              Redução de intermediários no processo de compra e venda, dando a
              cada negócio mais poder e autonomia.
            </DescMobile>
          )}
          {selectPoligon === 'trapezeRight' && (
            <DescMobile>
              Um melhor controle de gestão financeira, para cada etapa do
              processo.
            </DescMobile>
          )}
          {selectPoligon === 'triangle' && (
            <DescMobile>
              Confirmação de recebimento na tela do sistema, agilizando o
              processo e ajudando a mitigar fraudes.
            </DescMobile>
          )}

          <Arrows>
            <AiFillCaretLeft color="#777777" onClick={() => back()} />
            <AiFillCaretRight color="#777777" onClick={() => next()} />
          </Arrows>
        </CardMobile>
      </ResponsiveDesc>
    </PageBenefits>
  )
}
