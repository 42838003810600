import { useState } from 'react'
import { useSnackbar } from 'react-simple-snackbar'
import { CardContact, CardImage, CardInput, PageContact } from './styles'
import api from '../../services/api'
import img from '../../assets/images/hashlar_diversos.png'

export const Contact = () => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const [openSnackbar] = useSnackbar({
    style: {
      backgroundColor: 'white',
      color: 'black',
      zIndex: 9999
    },
    closeStyle: {
      color: 'black'
    }
  })

  const createContact = () => {
    if (email === '' || message === '') {
      openSnackbar('Por favor, preencha todos os campos.', [8000])
      return
    } else {
      let content = new FormData()

      content.append('email', email)
      content.append('message', message)

      const config = {
        headers: { 'content-type': 'multipart/json' }
      }

      api
        .post('core/contact/', content, config)
        .then(response => {
          openSnackbar('Contato enviado com sucesso!', [8000])
          setEmail('')
          setMessage('')
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  return (
    <PageContact id="contact">
      <CardImage>
        <img src={img} alt="Water Mark" />
      </CardImage>

      <CardContact>
        <CardInput>
          <h1>Entre em contato com a gente</h1>

          <span>E-mail</span>
          <input
            value={email}
            type="text"
            placeholder="E-mail"
            onChange={e => setEmail(e.target.value)}
          />

          <span>Mensagem</span>
          <textarea
            value={message}
            name="textarea"
            placeholder="Mensagem"
            rows="5"
            cols="50"
            onChange={e => setMessage(e.target.value)}
          ></textarea>

          <button onClick={createContact}>ENVIAR</button>
        </CardInput>
      </CardContact>
    </PageContact>
  )
}