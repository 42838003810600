import styled from 'styled-components'
import { width990 } from '../../responsive'

export const Container = styled.div`
  background-color: #4d9311;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5% 10%;
  margin-top: -10px;
  ${width990({ flexDirection: 'column', justifyContent: 'center' })}
`

export const Logo = styled.div`
  width: 40%;
  color: #ffffff;
  display: flex;
  align-items: center;
  ${width990({ width: '100%', margin: '20px 0', justifyContent: 'center' })}

  img {
    width: 10vw;
    min-width: 120px;
  }
`

export const Links = styled.div`
  width: 60%;
  ${width990({ width: '100%', margin: '20px 0', justifyContent: 'center' })}
`

export const List = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  list-style-type: none;
  ${width990({ flexWrap: 'wrap', gap: '25px', justifyContent: 'center' })}
`

export const Circles = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding-left: 3rem;
  gap: 10px;
  ${width990({
    flexWrap: 'wrap',
    gap: '25px',
    margin: '0',
    paddingLeft: '0',
    paddingBottom: '10px'
  })}
`

export const Item = styled.li`
  a {
    background-color: transparent;
    border: none;
    font-weight: 400;
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
  }
`

export const Circle = styled.div`
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 50%;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 23px;
  }

  .linkedin {
    margin-bottom: 5px;
  }
`
