import styled from 'styled-components';

export const NavContainer = styled.div`

.navbar {
  position: fixed !important;
  width: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10% !important;
  z-index: 70 !important;
  box-shadow: none !important;
  transition: all .2s;
}

.container-fluid {
  padding: 0;
}

.navbar-light .navbar-toggler {
  color: rgb(255 255 255 / 0%) !important;
}

.navbar-light .navbar-toggler {
  margin-left: auto !important;
}

.navbar-light .navbar-brand img {
  width: 12vw;
  min-width: 120px;
  max-width: 150px;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(255, 255, 255) !important;
  text-align: center;
}

.link_text{
  color: white !important;
  text-decoration: none;
}

.navbar-expand-lg .navbar-nav {
  justify-content: flex-end !important;
}

.links-container {
  min-width: 525px;
  height: 50px;
}

.navbar-expand-lg .navbar-nav {
  gap: 30px !important;
}


.navbar-light .navbar-nav .nav-link {
  justify-content: center !important;
}

.navbar:not(.top-nav-collapse) {
  background: transparent !important;
}

.bg-color:not(.top-nav-collapse) {
  background-color: #353535 !important;
}

@media screen and (max-width: 990px) {

  .navbar-expand-lg .navbar-nav {
    display: flex;
  }

  .navbar-light .navbar-nav .nav-link {
    padding-right: 20px;
    padding-bottom: 30px;
  }

  .navbar-light .navbar-nav .nav-link:first-child {
    padding-top: 35px !important;
  }

  .navbar-light .navbar-nav .nav-link:last-child {
    padding-bottom: 120px !important;
  }

  .navbar-light .navbar-nav .nav-link {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .navbar-expand-lg .navbar-nav {
    gap: 0 !important;
  }

  .carousel-item {
    padding-top: 125px !important;
  }

  .navbar:not(.top-nav-collapse) {
    background: #353535 !important;
  }

  .navbar {
    background-color: #353535 !important;
  }

  .space_back{
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100vh;
    background-color: transparent;
  }
}
`