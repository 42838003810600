import { useEffect, useState } from 'react'
import LogoHashlar from '../../assets/images/Group 7.png'

import {
  Arrow,
  CardInput,
  Center,
  Header,
  List,
  ListOrder,
  Logo,
  Table,
  Title
} from './styles'
import { Link, useParams } from 'react-router-dom'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import api from '../../services/api'
import { useSnackbar } from 'react-simple-snackbar'

export const FollowYourOrder = () => {
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [sendEmail, setSendEmail] = useState(0)
  const [list, setList] = useState([])
  const { phase } = useParams()

  const [openSnackbar] = useSnackbar({
    style: {
      backgroundColor: 'white',
      color: 'black',
      zIndex: 9999
    },
    closeStyle: {
      color: 'black'
    }
  })

  const validateEmail = () => {
    if (email === '') {
      openSnackbar('Por favor, preencha o email.', [8000])
    } else {
      const config = {
        headers: { 'content-type': 'multipart/json' }
      }

      api
        .post(`core/send-email-code/?email=${email}`, config)
        .then(response => {
          console.log(email);
          sessionStorage.setItem("email", email)
          setSendEmail(1)
        })
        .catch(error => {
          openSnackbar('O email preenchido não é válido.', [8000])
        })
    }
  }

  const haveCode = () => {

    if (email === '') {
      openSnackbar('Por favor, preencha o email.', [8000])
    } else {
      sessionStorage.setItem("email", email)
      setSendEmail(1)
    }
  }

  const validateCode = () => {

    if (code === '') {
      openSnackbar('Por favor, preencha o código.', [8000])
    } else {
      
      const email = sessionStorage.getItem("email")
      const config = {
        headers: { 'content-type': 'multipart/json' }
      }
      
      api
        .get(
          `core/get-all-orders-by-email?email=${email}&page_size=${10}&page=${1}&code=${code}`,
          config
        )
        .then(response => {
          sessionStorage.setItem("code", code)
          setSendEmail(2)
          setList(response.data.results)
        })
        .catch(error => {
          openSnackbar('O código preenchido não é válido.', [8000])
        })
    }
  }

  const returnScreen = () => {
    setSendEmail(sendEmail - 1)
  }

  useEffect(() => {
    if (phase === '2') {
      
      const config = {
        headers: { 'content-type': 'multipart/json' }
      }
      
      const email = sessionStorage.getItem("email")
      const code = sessionStorage.getItem("code")

      api
        .get(
          `core/get-all-orders-by-email?email=${email}&page_size=${10}&page=${1}&code=${code}`,
          config
        )
        .then(response => {
          setList(response.data.results)
        })
        .catch(error => {
          openSnackbar('O código preenchido não é válido.', [8000])
          setSendEmail(0)
        })

      setSendEmail(2)
    }
  }, []);

  return (
    <Center phase>
      <Header>
        <Arrow>
          {sendEmail === 0 ? (
            <Link to={'/'}>
              <BsFillArrowLeftCircleFill size="2.2em" />
            </Link>
          ) : (
            <BsFillArrowLeftCircleFill onClick={returnScreen} size="2.2em" />
          )}
        </Arrow>
        <Logo>
          <Link to={'/'}>
            <img alt="logo" src={LogoHashlar} />
          </Link>
        </Logo>
      </Header>

      <Title> Acompanhe o seu Pedido </Title>
      {sendEmail === 0 && (
        <CardInput>
          <span>E-mail</span>
          <input
            value={email}
            type="text"
            placeholder="E-mail"
            onChange={e => setEmail(e.target.value)}
          />

          <button onClick={validateEmail}>Gerar código</button>
          <button onClick={haveCode}>Já tenho o código</button>
        </CardInput>
      )}

      {sendEmail === 1 && (
        <CardInput>
          <span>Código</span>
          <input
            value={code}
            type="text"
            placeholder="Código"
            onChange={e => setCode(e.target.value)}
          />
          <button onClick={validateCode}>Validar</button>
        </CardInput>
      )}

      {sendEmail === 2 && (
        <ListOrder>
          <Table>
            <span> Pedido: </span>
            <span> Valor: </span>
            <span> Moeda: </span>
            <button>Detalhes</button>
          </Table>

          {list.map(obj => (
            <List key={obj.id}>
              <span> {obj.id} </span>
              <span> {obj.amount.toFixed(2)} </span>
              <span> {obj.coin_obj.name} </span>

              <Link to={`/details-order/${obj.id}`} state={{obj}}>
                <button>Detalhes</button>
              </Link>
            </List>
          ))}
        </ListOrder>
      )}

    </Center>
  )
}
