import styled from 'styled-components'

export const Center = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    margin: 0;
    font-weight: 500;
    font-size: 15px;
  }

  textarea {
    border: none;
    background: #efefef;
    padding: 20px;
    border-radius: 10px;
    resize: none;
    width: 100%;
    max-width: 400px;
    overflow: hidden;
    line-height: 1.3;
  }
`

export const Header = styled.div`
    display: flex;
    align-items: center;
`

export const Arrow = styled.div`
  padding-right: 50px;

  svg {
    color: #000000;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;

  span {
    padding: 15px 0 5px;
  }
`
export const Line = styled.div`
  margin: 50px 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  svg {
    cursor: pointer;
  }
`
export const QRCodeContent = styled.div`
  margin: 25px 0;
`

export const CenterContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Title = styled.h3`
  margin: 0;
  font-weight: bold;
`

export const Logo = styled.div`
  width: 40%;
  max-width: 200px;
  min-width: 120px;
  color: #ffffff;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    object-fit: cover;
  }
`

export const Text = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin: 0;
`
export const BtnAddress = styled.button`
  padding: 6px 16px;
  color: #ffffff;
  background: #525561;
  border-radius: 5px;
  border: none;
  margin: 20px 0;
`

export const Footer = styled.footer`
  width: 100%;
  opacity: 0.6;
  margin-top: 70px;

  p {
    font-weight: 600;
    margin-left: 15px;
  }
`

export const Images = styled.div`
  width: 100%;
  height: 17vh;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  img {
    width: 25vw;
    margin-top: 20px;
    max-width: 200px;
    min-width: 115px;
    margin-left: 15px;
  }
`
