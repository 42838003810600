import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import '../../global.css'
import { Navbar } from '../../components/navbar/index'
import { FirstPage } from '../../components/firstPage/index'
import { WhatIs } from '../../components/whatIs/index'
import { HowWorks } from '../../components/howWorks/index'
import { Benefits } from '../../components/benefits/index'
import { Contact } from '../../components/contact/index'
import { Footer } from '../../components/footer/index'
import { Solution } from '../../components/solution'
import SnackbarProvider from 'react-simple-snackbar'
import 'bootstrap/dist/css/bootstrap.min.css';

export const Home = () => {
  return (
    <SnackbarProvider>
      <div>
        <Navbar />
        <FirstPage />
        <div className="main-content">
          <WhatIs />
          <HowWorks />
          <Benefits />
          <Solution />
          <Contact />
          <Footer />
        </div>
      </div>
    </SnackbarProvider>
  )
}