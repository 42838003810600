import { Carrosel } from "./carrosel"
import { PageOne } from "./styles"

export const FirstPage = () => {
  return (
    <PageOne>
      <Carrosel/>
    </PageOne>
  )
}
