import { css } from 'styled-components'

export const width1600 = props => {
  return css`
    @media only screen and (max-width: 1600px) {
      ${props}
    }
  `
}

export const width1400 = props => {
  return css`
    @media only screen and (max-width: 1400px) {
      ${props}
    }
  `
}

export const width1100 = props => {
  return css`
    @media only screen and (max-width: 1100px) {
      ${props}
    }
  `
}

export const width990 = props => {
  return css`
    @media only screen and (max-width: 990px) {
      ${props}
    }
  `
}

export const width768 = props => {
  return css`
    @media only screen and (max-width: 768px) {
      ${props}
    }
  `
}

export const width700 = props => {
  return css`
    @media only screen and (max-width: 700px) {
      ${props}
    }
  `
}

export const width600 = props => {
  return css`
    @media only screen and (max-width: 600px) {
      ${props}
    }
  `
}

export const width480 = props => {
  return css`
    @media only screen and (max-width: 480px) {
      ${props}
    }
  `
}

export const width420 = props => {
  return css`
    @media only screen and (max-width: 420px) {
      ${props}
    }
  `
}

export const width350 = props => {
  return css`
    @media only screen and (max-width: 350px) {
      ${props}
    }
  `
}
