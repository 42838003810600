import styled from 'styled-components'

export const PageOne = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;

  img {
    object-fit: cover;
    min-height: 400px;
  }

  p {
    margin-bottom: 2.5rem;
  }

  .carousel,
  .carousel-item,
  .carousel-inner {
    max-height: 85vh !important;  
  }
  
`

export const Title = styled.h3`
  font-size: calc(2.232142857142857vw + 12.857142857142858px);
  font-weight: bold;
`

export const Text = styled.p`
  width: 100%;
  font-size: calc(0.4464285714285714vw + 14.571428571428571px);
`
