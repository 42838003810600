import { HashIcon } from '../../Icons'
import {
  CardFigure,
  CircleBig,
  CircleMedium,
  CircleSmall,
  Line,
  Text
} from './styles'

export const Figure = ({ label, hash, web }) => {
  return (
    <CardFigure>
      <CircleBig>
        <CircleMedium>
          {hash ? <HashIcon /> : <Text> {label} </Text>}
        </CircleMedium>
      </CircleBig>
      {
        web &&
        <>
          <Line />
          <CircleSmall />
        </>
      }
      
    </CardFigure>
  )
}
