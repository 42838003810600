import './styles'
import {
  ImageResponsive,
  PageHowWorks,
  Description,
  CardInfo,
  ConstContainer,
  CardBoard,
  CardMobile,
  LineMobile
} from './styles.js'
import { Figure } from './figure'
import { Board } from './board'

export const HowWorks = () => {
  return (
    <PageHowWorks id="how-works">
      <CardInfo>
        <h1>Como Funciona?</h1>

        <Description>
          A Hashlar concentra todos os processos junto às criptomoedas, wallets
          e exchanges. Diminuindo consideravelmente os prazos de implementação,
          impactos de desenvolvimento e custos operacionais.
        </Description>
      </CardInfo>

      <ConstContainer>
        <Figure web label="Vendedor" />

        <CardBoard>
          <Board orderNumber="01." label="Envia o pedido" right />
          <Board orderNumber="03." label="Número pedido" left right />
          <Board orderNumber="05." label="Status pagamento" left right />
        </CardBoard>

        <Figure web hash />

        <CardBoard>
          <Board orderNumber="02." label="Ordem de pagamento" right />
          <Board orderNumber="04." label="Notificação" left />
        </CardBoard>

        <Figure web label="Moedas" />
      </ConstContainer>

      <ImageResponsive>

        <CardMobile>
          <LineMobile>
            <Figure label="Vendedor" />
            <Board orderNumber="01." label="Envia o pedido" right />
            <Figure hash />
          </LineMobile>

          <LineMobile>
            <Figure hash />
            <Board orderNumber="02." label="Ordem de pagamento" right />
            <Figure label="Cripto Moedas" />
          </LineMobile>

          <LineMobile>
            <Figure label="Vendedor" />
            <Board orderNumber="03." label="Número pedido" left right />
            <Figure hash />
          </LineMobile>

          <LineMobile>
            <Figure label="Cripto Moedas" />
            <Board orderNumber="04." label="Notificação" left />
            <Figure hash />
          </LineMobile>

          <LineMobile>
            <Figure label="Vendedor" />
            <Board orderNumber="05." label="Status pagamento" left right />
            <Figure hash />
          </LineMobile>
        </CardMobile>
      </ImageResponsive>
    </PageHowWorks>
  )
}
