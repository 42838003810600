import logo from '../../assets/images/logo_branca.png'
import { Circles, Container, Item, Links, List, Logo } from './styles'
import { InstagramIcon, LinkedInIcon, TwitterIcon } from '../Icons'

export const Footer = () => {
  return (
    <Container>
      <Logo>
        <img alt="logo" src={logo} />
      </Logo>

      <Links>
        <List>
          <Item>
            <a href="#what-is">Sobre</a>
          </Item>
          <Item>
            <a href="#how-works">Como funciona</a>
          </Item>
          <Item>
            <a href="#benefits">Vantagens</a>
          </Item>
          <Item>
            <a href="#contact">Nossa Solução</a>
          </Item>
          <Item>
            <a href="#operation-flow">Contato</a>
          </Item>
        </List>
      </Links>

      <Circles>
        <Item>
          <a
            href="https://www.instagram.com/hashlar_pay/"
            rel="noreferrer"
            target="_blank"
          >
            <InstagramIcon color="white" />
          </a>
        </Item>
        <Item>
          <a
            href="https://www.linkedin.com/company/hashlar"
            rel="noreferrer"
            target="_blank"
          >
            <LinkedInIcon color="white" />
          </a>
        </Item>
        <Item>
          <a
            href="https://twitter.com/hashlar_pay"
            rel="noreferrer"
            target="_blank"
          >
            <TwitterIcon color="white" />
          </a>
        </Item>
      </Circles>
    </Container>
  )
}