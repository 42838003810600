import { PageWhatIs, Logo, Description } from './styles'
import img from '../../assets/images/logo.svg'

export const WhatIs = () => {
  return (
    <>
      <PageWhatIs id="what-is">
        <Logo>
          <img src={img} alt="Logo Hashlar" />
        </Logo>

        <Description>
          <p>
            A <b>Hashlar</b> é o primeiro gateway que integra pagamentos com
            criptoativos direto no setor imobiliário.
          </p>
          <p>
            Nossa solução pode ser integrada em imobiliárias, incorporadoras,
            fundos e demais canais digitais do segmento.
          </p>
          <p>
            Com a Hashlar, o setor imobiliário amplia oportunidades de compra e
            venda, dentro de um ambiente inovador, ágil e seguro.
          </p>
        </Description>
      </PageWhatIs>
    </>
  )
}