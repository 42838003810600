/* eslint-disable no-dupe-keys */

import styled from 'styled-components'
import { width1100, width768 } from '../../../responsive'

export const CardBoard = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
`

export const ArrowLeft = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 46px solid transparent;
  border-bottom: 46px solid transparent;
  border-left: ${props => props.color === 'white' && '46px solid #FFFFFF'};
  border-left: ${props => props.color === 'transparent' && '46px solid transparent'};
  
  @media only screen and (max-width: 768px) {
    display: ${props => props.color === 'transparent' && 'none'};
    border-left: ${props => props.color === 'white' && '20px solid #FFFFFF'};
  }
  
  ${width768({
    borderTop: '30px solid transparent',
    borderBottom: '30px solid transparent',
  })};
`

export const ArrowRight = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 46px solid transparent;
  border-bottom: 46px solid transparent;
  border-right: ${props => props.color === 'white' && '46px solid #FFFFFF'};
  border-right: ${props => props.color === 'transparent' && '46px solid transparent'};

  @media only screen and (max-width: 768px) {
    display: ${props => props.color === 'transparent' && 'none'};
    border-right: ${props => props.color === 'white' && '20px solid #FFFFFF'};
  }

  ${width768({
    borderTop: '30px solid transparent',
    borderBottom: '30px solid transparent',
  })};
`

export const Rectangle = styled.div`
  max-width: 250px;
  width: 20vw;
  height: 92px;
  background-color: rgb(0, 0, 0, 0.22);
  display: flex;
  justify-content: center;
  padding: 5px;
  align-items: center;
  ${width1100({ flexDirection: 'column' })};
  ${width768({ height: '60px', width: '100px' })};
`

export const Number = styled.span`
  font-weight: bold;
  color: #ffffff;
  font-size: calc(0.8928571428571428vw + 21.142857142857142px);
  margin-right: 15px;
  ${width1100({ marginRight: '0' })};
  ${width768({ fontSize: '15px' })};
`

export const Text = styled.span`
  width: 125px;
  color: #ffffff;
  font-weight: bold;
  font-size: calc(0.22vw + 14.28px);
  line-height: 1.1;
  ${width1100({ textAlign: 'center' })};
  ${width768({ fontSize: '12px', width: '100%' })};
`
