import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import LogoHashlar from '../../assets/images/Group 7.png'
import WaterMark from '../../assets/images/waterMark.png'
import {
  Arrow,
  BtnAddress,
  Center,
  CenterContainer,
  Footer,
  Header,
  Images,
  Info,
  Line,
  Logo,
  QRCodeContent,
  Text,
  Title
} from './styles'
import QRCode from 'react-qr-code'
import api from '../../services/api'
import { useEffect } from 'react'
import { IoIosRefresh } from 'react-icons/io'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'

export const DetailsOrder = () => {
  const { id } = useParams()
  const [info, setInfo] = useState(null)

  const copyAddress = () => {
    let textoCopiado = document.getElementById('address')
    textoCopiado.select()
    textoCopiado.setSelectionRange(0, 99999)
    document.execCommand('copy')
  }

  const requestId = () => {
    setInfo([])

    const config = {
      headers: { 'content-type': 'multipart/json' }
    }

    api
      .get(`core/order/by-id?id=${id}`, config)
      .then(response => {
        setInfo(response.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    requestId()
  }, [])

  return (
    <Center>
      <CenterContainer>
        <Header>
          <Arrow>
            <Link to={'/follow-your-order/2'}>
              <BsFillArrowLeftCircleFill size="2.2em" />
            </Link>
          </Arrow>
          <Logo>
            <Link to={'/'}>
              <img alt="logo" src={LogoHashlar} />
            </Link>
          </Logo>
        </Header>

        <Line>
          <Title> Pagamento </Title>
          <IoIosRefresh size="1.5em" onClick={requestId} />
        </Line>

        <Info>
          <Text>Valor: {info?.amount} </Text>
          <Text>Moeda: {info?.coin_obj?.name} </Text>
          <Text>Rede: {info?.coin_obj?.network} </Text>
          <Text>Memo: {info?.coin_obj?.memo} </Text>

          <span>Endereço para pagamento</span>
          <textarea
            rows="2"
            cols="25"
            type="text"
            id="address"
            value={info?.coin_obj?.address}
            readOnly
          />
        </Info>

        <BtnAddress onClick={copyAddress}>Copiar endereço</BtnAddress>
        <QRCodeContent>
          {info?.coin_obj?.address.length > 0 && (
            <QRCode size="120" value={info?.coin_obj?.address} />
          )}
        </QRCodeContent>
        <Info>
          <Text>
            Status:
            {info?.status === 'FINISH'
              ? ' PAGAMENTO FINALIZADO'
              : ' AGUARDANDO PAGAMENTO'}
          </Text>
        </Info>
      </CenterContainer>

      <Footer>
        <p>Powered by:</p>
        <Images>
          <img src={LogoHashlar} alt="Logo" />
          <img src={WaterMark} alt="Logo" />
        </Images>
      </Footer>
    </Center>
  )
}
