import styled from 'styled-components'
import { width990 } from '../../responsive'

export const PageContact = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  ${width990({ height: '100%' })}
`

export const CardImage = styled.div`
  width: 50%;
  height: 100%;
  ${width990({ display: 'none' })}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const CardContact = styled.div`
  width: 50%;
  ${width990({ width: '100%' })}
`

export const CardInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8% 18%;
  ${width990({ margin: '10% 10%' })}

  h1 {
    line-height: 1.1;
    margin-bottom: 25px;
    color: #261e1d;
  }

  span {
    color: #261e1d;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 10px;
  }

  input,
  textarea {
    border-radius: 5px;
    background: #e9e8e8;
    border: 0px solid transparent;
    padding: 8px 15px;
    margin-bottom: 25px;
    resize: none;
    font-size: 12px;
    font-weight: 500;
    color: #261e1d;
  }

  input::placeholder,
  textarea::placeholder {
    color: #261e1d;
    font-weight: 500;
  }

  input:focus,
  textarea:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }

  button {
    padding: 13px 0;
    background: #4d9311;
    font-weight: bold;
    font-size: 14px;
    color: white;
    width: 100%;
    border: 0px solid transparent;
    border-radius: 5px;
    margin-top: 20px;
  }
`
