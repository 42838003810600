import styled from 'styled-components'
import { width1600, width768 } from '../../../responsive'

export const CardFigure = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${width1600({ marginLeft: '-50px', marginRight: '-50px' })};
`

export const CircleBig = styled.div`
  background-color: #ffffff;
  width: 15vw;
  height: 15vw;
  max-width: 215px;
  max-height: 215px;
  min-width: 150px;
  min-height: 150px;
  border: 1px solid transparent;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${width768({ minWidth: '90px', minHeight: '90px' })};
`

export const CircleMedium = styled.div`
  background: #66d81d 0% 0% no-repeat padding-box;
  width: 10vw;
  height: 10vw;
  max-width: 150px;
  max-height: 150px;
  min-width: 100px;
  min-height: 100px;
  border: 1px solid transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgb(102, 216, 29, 0.28);
  ${width768({ minWidth: '70px', minHeight: '70px' })};

  svg {
    width: 80%;
  }
`

export const Text = styled.span`
  color: #4d9311;
  font-weight: bold;
  letter-spacing: 0px;
  font-size: calc(0.22vw + 14.28px);
  ${width768({ fontSize: '11px', width: '80%' })};
`

export const Line = styled.div`
  height: 430px;
  width: 1px;
  border: 5px solid #ffffff;
`

export const CircleSmall = styled.div`
  background-color: #ffffff;
  width: 85px;
  height: 85px;
  border: 1px solid transparent;
  border-radius: 100%;
`
